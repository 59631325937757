import React from 'react'
import { Button, Flex, DotsBG1Icon, BackgroundMobileLeft } from "@pancakeswap/uikit";
import styled from 'styled-components'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <Container>
          <ColImageLeft>
            <CsDotsBG1Icon/>
            <CsDotsBG2Icon/>
          </ColImageLeft>
          <ColImageRight>
            <CsDotsBG1RightIcon/>
            <CsDotsBG2RightIcon/>
            <CsDotsBG3RightIcon/>
          </ColImageRight>
        <ContainerImg>
          <CsImg src='/images/background_comming_soon.png?version=1.2' alt=''/>
          <CsImgMobile src='/images/page_not_found_mobile.png?version=1.0' alt=''/>
        </ContainerImg>
        <Flex flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CustomButton as="a" href="/">
            {t('Quay lại trag chủ')}
          </CustomButton>
        </Flex>
      </Container>
    </Page>
  )
}

export default NotFound

const CsDotsBG2Icon = styled(BackgroundMobileLeft)`
    position: absolute;
    top:120px;
    left: 80px;
    height: 123px;
    width: 123px;
    @media screen and (min-width: 1001px) and (max-width: 1280px){
      height: 80px;
      width: 80px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px){
      height: 60px;
      width: 60px;
    }
    @media screen and (max-width: 600px){
      height: 50px;
      width: 50px;
      top: 100px;
      left: 60px;
    }
` 

const ColImageLeft = styled(Flex)`
    width:280px;
    height: calc(100vh - 100px);
    position: absolute;
    bottom:0;
    left: -30px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        width:280px;
        left: -35px;
        bottom:0;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width:180px;
        left: -25px;
    }
    @media screen and (max-width: 600px){
      width:130px;
      left: -10px;
      bottom: 10px;
    }
`
const ColImageRight = styled(Flex)`
    width:500px;
    height: calc(100vh - 100px);
    position: absolute;
    top:0px;
    right: 0px;
    @media screen and (min-width: 1281px) and (max-width: 1400px) {
        width: 450px;
    }
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        width: 350px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
        width: 250px;
    }
    @media screen and (max-width: 600px){
        top:0px;
        width: 110px;
        z-index: 1;
    }
`
const CsDotsBG1Icon = styled(DotsBG1Icon)`
    position: absolute;
    bottom:50px;
    right: 0px;
    height: 65px;
    width: 65px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
      height: 65px;
      width: 65px;

    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
      height: 55px;
      width: 55px;
    }
    @media screen and (max-width: 600px){
      right: 20px;
      height: 35px;
      width: 35px;
      bottom:30px;
    }
`

const CsDotsBG1RightIcon = styled(DotsBG1Icon)`
    position: absolute;
    top: 20px;
    left: 0px;
    height: 95px;
    width: 95px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
      height: 75px;
      width: 75px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
        height: 65px;
        width: 65px;
    }
    @media screen and (max-width: 600px){
      height: 45px;
        width: 45px;
    }
`
const CsDotsBG2RightIcon = styled(DotsBG1Icon)`
    position: absolute;
    bottom: 0px;
    left: 100px;
    height: 115px;
    width: 115px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
      height: 75px;
      width: 75px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
        height: 65px;
        width: 65px;
    }
    @media screen and (max-width: 600px){
    bottom: 20px;
      left: 20px;
      height: 45px;
      width: 45px;
    }
`

const CsDotsBG3RightIcon = styled(BackgroundMobileLeft)`
    position: absolute;
    bottom:220px;
    right: 10px;
    height: 123px;
    width: 123px;
    @media screen and (min-width: 1001px) and (max-width: 1280px){
      height: 80px;
      width: 80px;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px){
      height: 60px;
      width: 60px;
    }
    @media screen and (max-width: 600px){
      height: 50px;
      width: 50px;
      bottom: 200px;
      left: 60px;
    }
` 
const Container = styled.div`
    position: relative;
    width:100%;
    min-height: calc(100vh - 100px);
    display:flex;
    justify-content:center;
    align-items:center;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    flex-direction: column;
    margin: 0 auto;
    &::before{
      content: '';
      position: absolute;
      width: 28px;
      height: 315px;
      left: -14px;
      top: -26px;
      background: #F9A138;
      border-radius: 56px;
      @media only screen and (max-width: 1024px) {
        width: 22px;
        height: 215px;
        left: -16px;
      }
      @media only screen and (max-width: 600px){
        left: -25px;
      }
    }
`
const CustomButton = styled(Button)`
    background: ${({ theme }) => theme.colors.primary};
    width: 261px;
    height: 65px;
    border-radius: 12px;
    font-weight: 700;
    margin-top:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
    @media only screen and (max-width: 600px) {
      width: 96%;
      height: 48px;
      font-size: 14px;
  }
`
const ContainerImg = styled(Flex)`
  justify-content: center;
`

const CsImg = styled.img`
  width: 567px;
  display: block;
  object-fit: contain;
  @media only screen and (max-width: 1024px) {
    width: 467px;
  }
  @media only screen and (max-width: 768px) {
    width: 367px;
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
`
const CsImgMobile = styled.img`
    display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    width: 100%;
  }
`